button {
  margin: 0;
}

.btn {
  display: block;
  border: none;
  outline: 0;
  padding: 0;
  font-size: 16px;
  text-transform: capitalize;
  font-family: 'Source Code Pro', monospace;
  background-color: transparent;
  cursor: pointer;

  &--category {
    color: #222;
  }

  &--save {
    margin: auto;
    margin-top: -10px;
    padding: 16px 32px;
    background-color: #ffd300;
    color: #222;
  }
}