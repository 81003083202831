.category-container {
  margin: auto;
  max-width: 320px;

  .icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    margin-right: 16px;
    border-radius: 50%;
    background-color: #ffd300;
  }
}

.category-active-container {
  position: relative;
  display: flex;
  align-items: center;
  padding: 16px 24px;
  padding-top: 0;
  cursor: pointer;

  &::after {
    content: '';
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    height: 1px;
    bottom: -1px;
    background-color: #eaeaea;
    width: 272px;
  }

  .category-active {
    display: flex;
  }

  .arrow {
    margin-left: auto;
    margin-right: 8px;

    i {
      font-size: 20px;
    }
  }

}

.category-options {
  display: none;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 272px;
  padding: 0 16px;
  background-color: #fff;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);

  > div {
    position: relative;
    display: flex;
    padding: 16px 0;

    .icon {
      transition: all 150ms ease-in-out;
    }

    &:hover {
      .icon {
        transform: scale(1.1);
      }
    }

    button {
      color: #222;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
      }
    }
}

  > div + div {
    border-top: 1px solid #eaeaea;
  }

  &.show {
    display: block;
  }
}

.content-container {
  margin: auto;


  p {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 0;
    padding: 0 4px;
    font-family: 'Lora', serif;
    font-size: 16px;
    color: #222;

    span {
      margin: 4px;
      cursor: pointer;
    }
  }
}

.show-options {
  .arrow i {
    transform: rotate(180deg) translateY(-2px);
  }

  .category-active {
    &::before {
      content: '';
      position: fixed;
      top: 64px;
      right: 0;
      bottom: 0;
      left: 0;
      cursor: default;
  }
}

  .category-options {
    display: block;
  }
}