body {
  margin: 0;
  margin-bottom: 2rem;
}

.col {
  margin: auto;
  max-width: 320px;
}

#main {
  padding: 16px;
}

#tag-container {
  position: relative;
  z-index: -1;
  opacity: 0;

  .tag,
  .tag-logo {
    cursor: default;
  }
}

.loading-container {
  margin-top: 24px;
  padding-top: 24px;
  text-align: center;
  font-family: 'Source Code Pro', monospace;
  font-size: 12px;

  .dot {
    opacity: 0;
    animation: dot .6s infinite;

    &--one {
      animation-delay: 0.1s;
    }

    &--two {
      animation-delay: 0.2s;
    }

    &--three {
      animation-delay: 0.3s;
    }
  }
}

@keyframes dot {
  0% { opacity: 0; }
 50% { opacity: 0; }
100% { opacity: 1; }
}

.header {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  padding: 12px 16px;
  border-bottom: 1px solid #eaeaea;

  .logo-container {
    height: 32px;

    .logo {
      img {
        width: 56px;
      }
    }
  }

  .controls-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;

    > div + div {
      margin-left: 32px;
    }
  }

  .info-container {
     .info {
       display: flex;
       justify-content: center;
       align-items: center;
       width: 32px;
       height: 32px;
       border-radius: 50%;
       background-color: #ffd300;
       cursor: pointer;

       &.open {
         &::before {
           content: '';
           position: fixed;
           top: 0;
           right: 0;
           bottom: 0;
           left: 0;
           background-color: rgba(255, 211, 0, 0.51);
         }
       }
     }
  }

}

.info-overlay {
  &.open {
    display: block;
  }

  display: none;

  > .info-content {
    position: absolute;
    top: 76px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #fff;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    max-width: 272px;
    width: 100%;
    padding: 24px 16px;
    font-family: 'Source Code Pro', monospace;

    h1 {
      margin-top: 0;
      margin-bottom: 16px;
      font-size: 20px;
      font-weight: 700;
    }

    i {
      position: absolute;
      top: 24px;
      right: 16px;
      font-size: 24px;
      cursor: pointer;
    }

    p {
      font-weight: 300;
      font-size: 16px;
      line-height: 1.5;

      & + p {
        margin-top: 24px;
      }
    }
  }
}