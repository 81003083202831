* {
  font-weight: 700;
  color: #222;
}

ul {
  padding: 0;
  margin: 0;

  li {
    background-color: yellow;
  }
}

.tag {
  position: relative;
  cursor: default;
  background-color: #222;
}

.tag-logo {
  cursor: default;
  position: relative;
  font-size: 11px;
  color: #222;
  text-transform: uppercase;
  font-family: 'Source Code Pro', monospace;
  padding-left: 4px;
  padding-right: 4px;
  background-color: #ffd300;
  line-height: 2;
}